import React from 'react';
import Helmet from 'react-helmet';
import './index.css';

const NotFoundPage = () => {
    return (
        <div className='bg'>
			<Helmet>
				<title>404... - Kyle Kramer</title>
				<link href='https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300&display=swap' rel='stylesheet' />
			</Helmet>

			<div>
				<h1>Whatcha doin'?</h1>
				<p>Nothing to see here folks (yet.. Yet!)</p>
			</div>
		</div>
    );
};

export default NotFoundPage;
